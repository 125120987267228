<template>
  <v-app>
    <Loading v-if="loading || !isAuthenticated" />
    <template v-else>
      <AppHeader :disabled="unauthorized"/>
      <v-main>
        <router-view :key="$route.params.id" />
      </v-main>
      <AppAlert />
      <AppFooter />
    </template>
  </v-app>
</template>

<script>
import AppHeader from '@/components/AppHeader'
import AppFooter from '@/components/AppFooter'
import AppAlert from '@/components/AppAlert'

export default {
  components: {
    AppHeader,
    AppFooter,
    AppAlert
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['Auth/accessToken']
    }
  },
  async created() {
    try {
      if (!(await this.$store.dispatch('Auth/login'))) {
        return
      }
      await Promise.all([
        this.$store.dispatch('Templates/load'),
        this.$store.dispatch('ListingHierarchies/getListingHierarchies'),
        this.$store.dispatch('SupportPolicies/getSupportPolicies'),
        this.$store.dispatch('OverrideTypes/getOverrideTypes'),
        this.$store.dispatch('AlertTypes/getAlertTypes'),
        this.$store.dispatch('Editions/getEditions'),
        this.$store.dispatch('Reports/getReports')
      ]).catch((x) => {
        this.unauthorized = x.message.endsWith('403')
      })
    } finally {
      this.loading = false
    }
  }
}
</script>
