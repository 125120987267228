<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
</style>

<style>
div.v-toolbar__content {
  max-width: 1399px;
  margin: auto;
}
</style>

<template>
  <div>
    <v-app-bar app flat>
      <v-toolbar-title>
        <router-link :to="{ name: 'Home' }" class="grey--text">
          <v-row align="center" class="mr-4" style="width: 250px" no-gutters>
            <v-img
              src="/logo.png"
              height="24"
              width="112"
              class="d-inline mr-2"
            />Lifecycle CMS
          </v-row>
        </router-link>
      </v-toolbar-title>
      <v-toolbar-items>
        <v-divider vertical />
        <ListingHierarchiesMenu>
          <template #activator="{ on }">
            <v-btn
              text
              v-on="on"
              :class="{ 'v-btn--active': $route.path.startsWith('/Listings') }"
              :disabled="disabled"
            >
              Listings
              <v-icon right>$dropdown</v-icon>
            </v-btn>
          </template>
        </ListingHierarchiesMenu>
        <v-divider vertical />
        <v-btn text :to="{ name: 'SourceData', query: { filter: 'All' } }" :disabled="disabled"
          >Source Data</v-btn
        >
        <v-divider vertical />
        <ToolsMenu @create-listing="createNewListingRecord = {}" :disabled="disabled" />
        <v-divider vertical />
        <ReportsMenu :disabled="disabled"/>
        <v-divider vertical />
        <v-btn text :to="{ name: 'Events' }" :disabled="disabled">Event History</v-btn>
        <v-divider vertical />
      </v-toolbar-items>
      <v-spacer />
      <v-toolbar-title>
        <v-menu nudge-bottom="45">
          <template #activator="{ on }">
            <span style="cursor: pointer" v-on="on"
              >Welcome {{ userName }}</span
            >
          </template>
          <v-list>
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>$logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-title>
    </v-app-bar>

    <CreateListingDialog
      v-model="createNewListingRecord"
      @cancel="createNewListingRecord = null"
      @created="onListingCreated"
    />
  </div>
</template>

<script>
import CreateListingDialog from '@/areas/listings/components/CreateListingDialog.vue'
import ListingHierarchiesMenu from '@/areas/listingHierarchies/components/ListingHierarchiesMenu.vue'
import ToolsMenu from '@/components/ToolsMenu.vue'
import ReportsMenu from '@/components/ReportsMenu.vue'

export default {
  props: {
    disabled: Boolean
  },
  components: {
    CreateListingDialog,
    ListingHierarchiesMenu,
    ToolsMenu,
    ReportsMenu
  },
  data: () => ({
    createNewListingRecord: null,
    publishToPreview: false,
    publishToLive: false
  }),
  computed: {
    userName() {
      const account = this.$auth.getAccount()

      if (account) {
        return account.name
      }

      return ''
    }
  },
  methods: {
    onListingCreated(listing) {
      this.createNewListingRecord = null
      this.$router.push({
        name: 'Listing',
        params: { id: listing.id.toString() }
      })
    },
    logout() {
      this.$store.dispatch('Auth/logout')
    }
  }
}
</script>
