import router from '@/router'

const routes = [
  {
    path: '/Manage',
    name: 'Manage',
    meta: {
      title: 'Manage'
    },
    components: {
      default: () => import(/* webpackChunkName: "Manage" */ './views/Manage.vue')
    },
    children: [
      {
        name: 'Editions',
        path: '/Manage/Editions',
        components: {
          manage: () => import(/* webpackChunkName: "ManageEditions" */ '@/areas/editions/views/ManageEditions.vue')
        },
        meta: {
          title: 'Manage Editions'
        }
      },
      {
        name: 'Families',
        path: '/Manage/Families',
        components: {
          manage: () => import(/* webpackChunkName: "ManageFamilies" */ '@/areas/listingHierarchies/views/ManageFamilies.vue')
        },
        meta: {
          title: 'Manage Families'
        }
      },
      {
        name: 'Groups',
        path: '/Manage/Groups',
        components: {
          manage: () => import(/* webpackChunkName: "ManageGroups" */ '@/areas/listingHierarchies/views/ManageGroups.vue')
        },
        meta: {
          title: 'Manage Groups'
        }
      },
      {
        name: 'Products',
        path: '/Manage/Products',
        components: {
          manage: () => import(/* webpackChunkName: "ManageProducts" */ '@/areas/listingHierarchies/views/ManageProducts.vue')
        },
        meta: {
          title: 'Manage Products'
        }
      },
      {
        name: 'Lifecycle2Go Templates',
        path: '/Manage/Templates',
        components: {
          manage: () => import(/* webpackChunkName: "Templates" */ '@/areas/lifecycle2go/templates/views/ManageTemplates.vue')
        },
        meta: {
          title: 'Manage Lifecycle2Go Templates'
        }
      },
      {
        name: 'Lifecycle2Go Report',
        path: '/Manage/Lifecycle2GoReport',
        components: {
          manage: () => import(/* webpackChunkName: "Lifecycle2GoReport" */ '@/areas/lifecycle2go/reports/views/Lifecycle2GoReport.vue')
        },
        meta: {
          title: 'Lifecycle2Go Report'
        }
      },
      {
        name: 'Azure Retirements',
        path: '/Manage/AzureRetirements',
        components: {
          manage: () => import(/* webpackChunkName: "AzureRetirements" */ '@/areas/azureRetirements/views/AzureRetirements.vue')
        },
        meta: {
          title: 'Azure Retirements'
        }
      }
    ]
  }
]

router.addRoutes(routes)

export default routes
