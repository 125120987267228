















































import { mask } from 'vue-the-mask'
import moment, { Moment } from 'moment'
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: String,
    disabled: Boolean,
    readonly: Boolean,
    rules: Array,
    show: Boolean,
    allowedDates: Function,
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  directives: { mask },
  data() {
    return {
      showPicker: false
    }
  },
  watch: {
    show(newValue) {
      this.showPicker = newValue
    }
  },
  computed: {
    date(): Moment {
      if (!this.value) {
        return
      }
      return moment(this.value, true)
    },
    dateIsoString(): string {
      if (this.date?.isValid()) {
        return this.date.format('YYYY-MM-DD')
      }
      return ''
    },
    dateString(): string {
      if (this.date?.isValid()) {
        return this.date.format('MM/DD/YYYY')
      }
      return this.value
    }
  },
  methods: {
    onInput(val) {
      const date = moment(val, 'MM/DD/YYYY', true)
      let dateString = val

      if (date.isValid()) {
        dateString = date.format('YYYY-MM-DD')
      }

      // hide picker
      this.showPicker = false
      this.$emit('input', dateString || null)
    }
  }
})
